
// Import React Components
import React, { useState, useContext } from 'react';
import GlobalContext from '../../context/GlobalContext';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import useCBBQuery from '../../hooks/useCBBQuery';

// Import React Bootstrap Components
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';

// Import Components + Logo SVG
import AppSelect from '../selects/AppSelect';
import imageUrls from '../../images/gcsImages';
import useScreenSize from '../../hooks/useScreenSize';

// And The Navbar Component
function AppNavbar() {
    // const okay = '123';
    // useNavigate, useContext, useState
    const { userData, setShowLogin, conferenceInfosObj, trialPlayers } = useContext(GlobalContext);
    const [isConferenceHovered, setIsConferenceHovered] = useState(false);
    const [isTeamHovered, setIsTeamHovered] = useState(false);
    const [isToolsHovered, setIsToolsHovered] = useState(false);
    const [isStatsHovered, setIsStatsHovered] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const setHoveredsFalse = () => {
        setIsStatsHovered(false);
        setIsToolsHovered(false);
        setIsTeamHovered(false);
        setIsConferenceHovered(false);
    };
    const handleClick = () => {
        window.scrollTo({ top: 0, left: 0 });
        setHoveredsFalse();
        setExpanded(false);
    };

    // console.log('logging state: ', { isStatsHovered, isToolsHovered, isTeamHovered });

    // Assess Where on the Page We Are
    const { isMd } = useScreenSize();
    const location = useLocation();
    const { adminTier, defaultCompetition } = useContext(GlobalContext);
    const path = location.pathname;

    // Booleans to check what page we're on
    let isTools = path.slice(1, 6) === 'tools' ? true : false;
    let isStats = path.slice(1, 6) === 'stats' ? true : false;
    let isAbout = path.slice(1, 6) === 'about' ? true : false;
    let isBlog = path.slice(1, 9) === 'insights' ? true : false;
    let isTips = path.slice(1, 16) === 'getting-started' ? true : false;
    let isUpgrade = path.slice(1, 21) === 'my-account/subscribe' ? true : false;

    // set default variables - competitionId(s), teamId, etc.
    let competitionId = defaultCompetition.value;
    let isMale = competitionId === 38409;
    let teamId = userData?.user?.team?.teamId || null;
    let divisionId = userData?.user?.team?.divisionId || 1;
    let conferenceId = userData?.user?.conference?.value || null;
    let appsCompetitionId = userData?.user?.team?.gender === 'MALE' ? 38409 : 38529 || 38409; //        update at start of season
    let portalCompetitionId = userData?.user?.team?.gender === 'MALE' ? 36046 : 36045 || 36046; //      update in Jan/Feb when transitioning to new season's transfer portal
    let draftCompetitionId = userData?.user?.team?.gender === 'MALE' ? 38409 : 38529 || 38409; //       update in Jan/Feb after hard-coding draft prospects for new draft
    let tourneyCompetitionId = userData?.user?.team?.gender === 'MALE' ? 36046 : 36045 || 36046; //     update in March before start of march madness 68-team tournament
    let nilCompetitionId = 38409; //                                                                    mens only, update when you have NIL predictions for the next year
    let tournamentId = userData?.user?.team?.gender === 'MALE' ? 2225 : 2227 || 2225; //                update in March before start of march madness 68-team tournament
    let usersConfId = userData?.user?.conference?.value || '';

    // set default variables - strings, urls, names, etc.
    let latestDate = (new Date()).toISOString().slice(0, 10).replace(/-/g, '');
    let usersTeamLabel = userData?.user?.team?.teamMarket || '';
    let usersConfLabel = conferenceInfosObj?.[usersConfId]?.conferenceShortName || '';
    let userTier = userData?.user?.tier?.value || 0;
    let isAdmin = adminTier.value === 2;

    // set default variables - team, conference link
    let isLoggedIn = userData.user ? true : false;
    let usersTeamLink = isLoggedIn ? `/stats/${competitionId}/teams/${teamId}/overview` : '';
    let usersConfLink = isLoggedIn ? `/stats/${competitionId}/conferences/${usersConfId}/team-box` : '';

    // useCBBQuery Data Fetching - fetch for team (players on team) and for conference (teams in conference)
    const { data: teamInfos = [], isError: isError1 } = useCBBQuery({ ep: 'teams', cfg: { conferenceId, pass: !conferenceId } });
    const { data: teamPlayerInfos = [], isError: isError2 } = useCBBQuery({ ep: 'players', cfg: { competitionId, teamId, pass: !(competitionId && teamId) } });
    if (isError1 || isError2) { console.log('Data Fetching Error'); }

    // Create Teams-In-Conf Links, (array || []) to error handle W.filter is not a function
    const teamsInConferenceLinks = userTier <= 1 ? null : (teamInfos || [])
        .filter(row => isMale ? row.gender === 'MALE' : row.gender === 'FEMALE')
        .map(row => {
            const linkTo = isLoggedIn ? `/stats/${competitionId}/teams/${row.teamId}/overview` : '';
            const linkName = isLoggedIn ? `${row.teamMarket}${row.teamName ? ` ${row.teamName}` : ''}` : '';
            return (
                <NavDropdown.Item key={row.teamId} as={Link} to={linkTo} onClick={handleClick}>
                    {linkName}
                </NavDropdown.Item>
            );
        });

    // player names for dropdown
    const trialPlayerIds = trialPlayers.filter(row => row.for === 'user').map(row => row.playerId);
    const linkPlayerInfos = userTier === 1
        ? teamPlayerInfos.filter(row => trialPlayerIds.includes(row.playerId))
        : teamPlayerInfos;

    // [...(arr || [])] handles both fallback to [], and not mutating initial array
    const playersOnTeamLinks = ([...(linkPlayerInfos || [])])
        .sort((a, b) => a.fullName > b.fullName ? 1 : -1)
        .map(row => {
            const linkTo = isLoggedIn ? `/stats/${competitionId}/players/${row.playerId}/overview` : '';
            const linkName = isLoggedIn ? row.fullName : '';
            return (
                <NavDropdown.Item key={row.playerId} as={Link} to={linkTo} onClick={handleClick}>
                    {linkName}
                </NavDropdown.Item>
            );
        });


    // Creating Navbar Elements (individual links & dropdowns filled with links) for Organization

    // Dropdown 1 - Stats Dropdown
    const statsDropdown =
        (<NavDropdown title='Stats'
            className={`stats-dropdown ${isStats ? 'selected' : ''}`}
            onMouseEnter={() => setIsStatsHovered(true)}
            onMouseLeave={() => setIsStatsHovered(false)}
            // onClick={() => setIsStatsHovered(!isStatsHovered)} // setIsToolsHovered(false)
            // onClick={handleClick}
            onClick={() => setIsToolsHovered(false)}
            show={isStatsHovered}
        >
            <NavDropdown.Item as={Link} to={'/stats/home'} onClick={handleClick}>
                Stats Overview
            </NavDropdown.Item>
            <NavDropdown.Divider />

            {userTier >= 1 &&
                <NavDropdown.Item as={Link} to={`/stats/${competitionId}/division/d${divisionId}/team-box`} onClick={handleClick}>
                    Division Stats
                </NavDropdown.Item>
            }
            {userTier >= 1 &&
                <NavDropdown.Item as={Link} to={`/stats/conference-home/${competitionId}`} onClick={handleClick}>
                    Conference Stats
                </NavDropdown.Item>
            }
            {userTier >= 1 &&
                <NavDropdown.Item as={Link} to={`/stats/${draftCompetitionId}/draft/player-box`} onClick={handleClick}>
                    Draft Prospects
                </NavDropdown.Item>
            }
            {userTier >= 1 &&
                <NavDropdown.Item as={Link} to={`/stats/${tourneyCompetitionId}/tournaments/${tournamentId}/team-box`} onClick={handleClick}>
                    Tournament Stats
                </NavDropdown.Item>
            }
            {userTier >= 3 &&
                <NavDropdown.Item as={Link} to={`/stats/${portalCompetitionId}/portal/overview`} onClick={handleClick}>
                    Transfer Portal
                </NavDropdown.Item>
            }
            {userTier >= 3 &&
                <NavDropdown.Item as={Link} to={`/stats/${competitionId}/transfers/${divisionId}/overview`} onClick={handleClick}>
                    Transfer History
                </NavDropdown.Item>
            }
            {userTier >= 3 &&
                <NavDropdown.Item as={Link} to={`/streaming/${competitionId}`} onClick={handleClick}>
                    Live Game Stats
                </NavDropdown.Item>
            }
            {userTier >= 3 &&
                <NavDropdown.Item as={Link} to={`/stats/${nilCompetitionId}/nil/player-box`} onClick={handleClick}>
                    NIL Predictions
                </NavDropdown.Item>
            }
        </NavDropdown>);

    // Dropdown 2 - Tools Dropdown
    const toolsDropdown =
        (<NavDropdown
            title='Tools'
            className={`${isTools ? 'selected' : ''}`}
            onMouseEnter={() => setIsToolsHovered(true)}
            onMouseLeave={() => setIsToolsHovered(false)}
            onClick={() => { setIsStatsHovered(false); }}
            show={isToolsHovered}
        >
            <NavDropdown.Item as={Link} to={'/tools/home'} onClick={handleClick}>
                Tools Overview
            </NavDropdown.Item>
            <NavDropdown.Divider />

            <NavDropdown.Item as={Link} to={'/tools/shot-charts'} onClick={handleClick}>
                Shot Charts App
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to={'/tools/team-scatter'} onClick={handleClick}>
                Team Scatter
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to={'/tools/player-scatter'} onClick={handleClick}>
                Player Scatter
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to={`/tools/scoreboard/${competitionId}/d${divisionId}/${latestDate}`} onClick={handleClick}>
                NCAA Scoreboard
            </NavDropdown.Item>
            {userTier >= 1 &&
                <NavDropdown.Item as={Link} to={'/tools/bar-charts'} onClick={handleClick}>
                    Bar Charts App
                </NavDropdown.Item>
            }
            {userTier >= 1 &&
                <NavDropdown.Item as={Link} to={'/tools/comparisons'} onClick={handleClick}>
                    Comparisons App
                </NavDropdown.Item>
            }
            {userTier >= 1 &&
                <NavDropdown.Item as={Link} to={`/tools/game-deciding-shots/${appsCompetitionId}/d${divisionId}`} onClick={handleClick}>
                    Game Deciding Shots
                </NavDropdown.Item>
            }
            {userTier >= 1 &&
                <NavDropdown.Item as={Link} to={`/tools/scoring-streaks/${appsCompetitionId}/d${divisionId}`} onClick={handleClick}>
                    Streaks & Droughts
                </NavDropdown.Item>
            }
            {userTier >= 1 &&
                <NavDropdown.Item as={Link} to={'/tools/diy-shot-charts'} onClick={handleClick}>
                    DIY Shot Charts
                </NavDropdown.Item>
            }
            {/* {userTier >= 1 &&
                <NavDropdown.Item as={Link} to={`/tools/game-calculator`} onClick={handleClick}>
                    Game Calculator
                </NavDropdown.Item>
            } */}
            {userTier >= 1 &&
                <NavDropdown.Item as={Link} to={'/tools/model-fitting'} onClick={handleClick}>
                    Linear Regressions
                </NavDropdown.Item>
            }
            {userTier >= 1 &&
                <NavDropdown.Item as={Link} to={`/tools/game-explorer/${competitionId}/d${divisionId}`} onClick={handleClick}>
                    Game Explorer
                </NavDropdown.Item>
            }
        </NavDropdown>);

    // Dropdown 3, players-on-team dropdown
    const playersOnTeamDropdown =
        (<NavDropdown
            title={usersTeamLabel}
            onMouseEnter={() => setIsTeamHovered(true)}
            onMouseLeave={() => setIsTeamHovered(false)}
            show={isTeamHovered}
        >
            <NavDropdown.Item as={Link} to={usersTeamLink} onClick={handleClick}>
                {usersTeamLabel}
            </NavDropdown.Item>
            <NavDropdown.Divider />
            {playersOnTeamLinks}
        </NavDropdown>);

    // Dropdown 4, teams-in-conferences dropdown
    const teamsInConferenceDropdown =
        (<NavDropdown
            title={usersConfLabel}
            onMouseEnter={() => setIsConferenceHovered(true)}
            onMouseLeave={() => setIsConferenceHovered(false)}
            show={isConferenceHovered}
        >
            <NavDropdown.Item as={Link} to={usersConfLink} onClick={handleClick}>
                {usersConfLabel}
            </NavDropdown.Item>
            <NavDropdown.Divider />
            {teamsInConferenceLinks}
        </NavDropdown>);

    // Dropdowns 5+, no more dropdowns but rather Individual Links
    const homeLink = (
        <Nav.Link
            as={Link}
            to=''
            onClick={handleClick}
        >Home</Nav.Link>
    );
    const teamOnlyLink = (<Nav.Link as={Link} to={usersTeamLink}>{usersTeamLabel}</Nav.Link>);
    const conferenceOnlyLink = (<Nav.Link as={Link} to={usersConfLink}>{usersConfLabel}</Nav.Link>);
    const adminLink = (
        <Nav.Link
            as={Link}
            to='admin'
            onClick={handleClick}
        >Admin</Nav.Link>);

    const signupLinkAdmin = (
        <Nav.Link
            as={Link}
            to='/signup'
            onClick={handleClick}
        >Signup-A</Nav.Link>);

    const insightsLink = (
        <Nav.Link
            className={isBlog ? 'selected' : ''}
            as={Link}
            to='/insights/home'
            onClick={handleClick}
        >Insights</Nav.Link>);

    const upgradeLink =
        (<Nav.Link
            className={`orange ${isUpgrade ? 'selected' : ''}`}
            as={Link}
            to='/my-account/subscribe'
            onClick={handleClick}
        >Upgrade</Nav.Link>);

    const tutorialsLink =
        (<Nav.Link
            className={isTips ? 'selected' : ''}
            as={Link}
            to='/getting-started'
            onClick={handleClick}
        >Tutorials</Nav.Link>);

    const aboutUsLink =
        (<Nav.Link
            className={isAbout ? 'selected' : ''}
            as={Link}
            to='/about'
            onClick={handleClick}
        >About</Nav.Link>);

    const myAccountLink =
        (<Nav.Link
            as={Link}
            to='/my-account/settings'
            onClick={handleClick}
        >Account</Nav.Link>);

    const signupLink =
        (<Nav.Link
            className='redirect-to-signup-button'
            as={Link}
            to='/signup'
            onClick={handleClick}
        >Signup</Nav.Link>);

    const loginLink =
        (<Nav.Link
            className='login-button'
            onClick={() => { setHoveredsFalse(); setExpanded(false); setShowLogin(true); }}
        >
            Login
        </Nav.Link>);


    // and return!
    const isNavbarCollapsed = isMd;
    return (
        <Navbar expanded={expanded} className='top-navbar' fixed='top' expand='lg'>
            {/* Logo */}
            {!isNavbarCollapsed &&
                <div className='navbar-brand-wrapper'>
                    <Navbar.Brand>
                        <Link to='/'>
                            <img alt='company logo' src={imageUrls.cbbLogoWhite} />
                        </Link>
                    </Navbar.Brand>
                </div>
            }

            {/* Hamburger Icon */}
            <Navbar.Toggle className='cbb-hamburger' aria-controls='basic-navbar-nav' onClick={() => setExpanded(expanded ? false : 'expanded')} />

            {/* Options Menu: Dropdowns + Options */}
            <Navbar.Collapse id='basic-navbar-nav'>
                <div />
                <Nav className='center-nav'>
                    {isNavbarCollapsed && homeLink}
                    {statsDropdown}
                    {toolsDropdown}
                    {isLoggedIn && userTier === 0 && teamOnlyLink}
                    {isLoggedIn && userTier >= 1 && playersOnTeamDropdown}
                    {isLoggedIn && userTier === 0 && conferenceOnlyLink}
                    {isLoggedIn && userTier >= 1 && teamsInConferenceDropdown}
                    {insightsLink}
                    {tutorialsLink}
                    {aboutUsLink}
                    {isLoggedIn && myAccountLink}
                    {isLoggedIn && isAdmin && adminLink}
                    {isLoggedIn && isAdmin && signupLinkAdmin}
                    {isLoggedIn && userTier === 1 && upgradeLink}
                </Nav>

                {/* Sign up, Login when Logged Out */}
                {!isLoggedIn && <div className='forAlignment' style={{ width: 200 }} />}
                {/* {!isLoggedIn &&
                    <Nav className='signup-login-nav'>
                        {signupLink}
                        {loginLink}
                    </Nav>
                } */}

                {/* App Search when logged in */}
                {isLoggedIn && !isNavbarCollapsed &&
                    <Nav className='navbar-search-wrapper '>
                        {isLoggedIn && <AppSelect />}
                    </Nav>
                }
            </Navbar.Collapse>

            {/* Show AppSelect in Top Right ALWAYS on mobile */}
            {isNavbarCollapsed && isLoggedIn && <>
                <Nav className='navbar-search-wrapper '>
                    {<AppSelect />}
                </Nav>
            </>}

            {!isLoggedIn &&
                <Nav className='signup-login-nav' style={{ flexDirection: 'row', position: 'absolute', top: 8, right: 5 }}>
                    {signupLink}
                    {loginLink}
                </Nav>
            }

            {/* {isNavbarCollapsed && !isLoggedIn && <>
                <Nav className='signup-login-nav' style={{ display: 'flex' }}>
                    {signupLink}
                </Nav>
            </>} */}

        </Navbar>
    );
}

export default AppNavbar;
